import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'simple-line-icons/css/simple-line-icons.css';
import './resources/styles/Bootstrap/stylesheets/bootstrap.scss';
import './resources/styles/MB/sass/admin/default.scss';

import 'react-datepicker/dist/react-datepicker.css';
import 'video-react/dist/video-react.css';
import 'simple-line-icons/css/simple-line-icons.css';
import 'react-table/react-table.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-image-lightbox/style.css';

import React, { Component } from 'react';
import RootContainer from './RootContainer';
import { Provider } from 'react-redux';

import configureStore from './store';

const { store, history } = configureStore();

export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <RootContainer history={history} />
      </Provider>
    );
  }
}
