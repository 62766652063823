import fr from 'react-intl/locale-data/fr';
import LocaleDataFr from './lang/fr.json';
import moment from 'moment';
import 'moment/locale/fr';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { addLocaleData, IntlProvider } from 'react-intl';
import Loadable from 'react-loadable';
import LoadingComponent from './LoadingComponent';

addLocaleData([...fr]);

const Security = Loadable({
  loader: () => import('./Security'),
  loading: LoadingComponent
});
const Basepage = Loadable({
  loader: () => import('./Basepage'),
  loading: LoadingComponent
});
const Homepage = Loadable({
  loader: () => import('./components/Homepage/Homepage'),
  loading: LoadingComponent
});

const TransitionedPage = WrappedComponent => {
  const TransitionedComponent = props => (
    <CSSTransition
      transitionAppear={true}
      transitionAppearTimeout={600}
      transitionEnterTimeout={600}
      transitionLeaveTimeout={200}
      transitionName="slide"
      timeout={600}
    >
      <WrappedComponent {...props} />
    </CSSTransition>
  );
  return TransitionedComponent;
};

moment.locale('fr');

class RootContainer extends Component {
  state = {
    currentLocale: 'fr',
    messages: LocaleDataFr
  };

  render() {
    const { currentLocale, messages } = this.state;
    const { history } = this.props;

    return (
      <IntlProvider
        locale={currentLocale}
        key={currentLocale}
        messages={messages}
      >
        <Router history={history}>
          <Switch>
            <Route path="/:id" exact strict component={Security} />
            <Route path="/login/" exact strict component={Security} />
            <Basepage>
              <Route
                path="/home/"
                exact
                strict
                component={TransitionedPage(Homepage)}
              />
              <Route path="/" exact render={() => <Redirect to="/home/" />} />
            </Basepage>
          </Switch>
        </Router>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.authReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RootContainer);
